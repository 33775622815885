import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Skeleton from "react-loading-skeleton";

import { useCollection } from "@/hooks/resources";
import MenuPageLayout from "@/components/layouts/MenuPageLayout";
import { useAppContext } from "@/contexts/app-context";
import SongType from "@/types/song-type";

import "react-loading-skeleton/dist/skeleton.css";
import { Button } from "@/components/button";
import { request } from "@/utils/request";
import { useState } from "react";

const Songs = () => {
  const {
    state: { currentUser, isLoadingCurrentUser, hasActiveSubscription },
  } = useAppContext();
  const [songs, { isLoading: isLoadingSongs }] =
    useCollection<SongType>("/songs");

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubscribe = async () => {
    setIsSubmitting(true);
    const response = await request.post<{ redirectUrl: string }>(
      "/stripe/checkout/sessions",
      {
        cancelUrl: window.location.href,
      },
    );

    if (response.ok && response.data?.redirectUrl) {
      window.location.href = response.data.redirectUrl;
    }

    setIsSubmitting(false);
  };

  return (
    <MenuPageLayout title="Songs" className="relative overflow-clip">
      <div className="grid grid-cols-2 sm:flex flex-wrap justify-center gap-4 w-full">
        {!isLoadingSongs
          ? (songs || []).map((song) => (
              <div
                key={song.id}
                className="sm:w-[220px] rounded border p-4 flex flex-col gap-4 items-center bg-gray-50"
              >
                <img
                  src={song.image.url}
                  className="rounded-full size-24 sm:size-32 bg-gray-200 p-2"
                />

                <h4 className="text-center">{song.name}</h4>

                <Link
                  to={`/songs/${song.id}/play`}
                  className="btn-primary w-full"
                >
                  PLAY
                  <FontAwesomeIcon icon="play" />
                </Link>

                <Link to={`/songs/${song.id}`} className="text-blue-500">
                  Preview
                </Link>
              </div>
            ))
          : [...Array(15)].map((_, index) => (
              <div
                key={index}
                className="sm:w-[220px] rounded border p-4 flex flex-col gap-4 items-center bg-gray-50"
              >
                <Skeleton
                  circle
                  containerClassName="size-24 sm:size-32 leading-none"
                  className="size-24 sm:size-32"
                />

                <Skeleton
                  containerClassName="w-2/3 leading-none"
                  className="h-6"
                />
                <Skeleton
                  containerClassName="w-full leading-none"
                  className="h-12"
                />
                <Skeleton
                  containerClassName="w-1/2 leading-none"
                  className="h-6"
                />
              </div>
            ))}
      </div>

      {!isLoadingCurrentUser && !hasActiveSubscription && (
        <div className="absolute inset-0 bg-black/25 pt-8 px-4 flex justify-center items-start">
          <div className="bg-white p-8 rounded-lg ring-4 ring-amber-400 flex flex-col gap-y-4 items-center w-[500px] max-w-full text-center">
            {(currentUser?.subscriptions || []).length > 0 ? (
              <>
                <h2 className="font-semibold">Your membership has expired</h2>

                <p>Re-subscribe to continue playing.</p>

                <Button
                  type="button"
                  className="btn-secondary px-8"
                  onClick={handleSubscribe}
                  loading={isSubmitting}
                >
                  Re-subscribe
                </Button>
              </>
            ) : (
              <>
                <h2 className="font-semibold">
                  A membership is required to play
                </h2>

                <p>Subscribe to start playing.</p>

                <Button
                  type="button"
                  className="btn-secondary px-8"
                  onClick={handleSubscribe}
                  loading={isSubmitting}
                >
                  Subscribe
                </Button>
              </>
            )}
          </div>
        </div>
      )}
    </MenuPageLayout>
  );
};

export default Songs;
